
export default {
    name: 'Product-Recently',
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            history: {
                visible: false,
                list: []
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getViewedAjax()
    },
    methods: {
        getViewedAjax() {
            this.$api.common.getProductHistory({
                size: 60,
                dd: false
            }).then(response => {
                const res = response.data || {};
                this.history.list = res.items || []
                this.history.visible = !!this.history.list.length
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Recently Viewed'),
                keywords: this.$translate('Recently Viewed'),
                description: this.$translate('Recently Viewed'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
