
import ProductSeo from './components/ProductSeo'
import ProductBanner from './components/ProductBanner'
import ProductActive from './components/ProductActive'
import ProductActivePopup from './components/ProductActivePopup'
import ProductInfo from './components/ProductInfo'
import ProductSku from './components/ProductSku'
import ProductCard from './components/ProductCard'
import ProductVip from './components/ProductVip'
import ProductNotice from './components/ProductNotice'
import ProductResourceCoupon from './components/ProductResourceCoupon'
import ProductPolicy from './components/ProductPolicy'
import ProductMost from './components/ProductMost'
import ProductBundle from './components/ProductBundle'
import ProductDesc from './components/ProductDesc'
import ProductResource from './components/ProductResource'
import ProductReview from './components/ProductReview'
import ProductAd from './components/ProductAd'
import ProductEmpty from './components/ProductEmpty'
import ProductRec from './components/ProductLike'
import ProductViewed from './components/ProductViewed'
import ProductKeyword from './components/ProductKeyword'
import ProductRelatedCate from './components/ProductRelatedCate'
import ProductResourceThree from './components/ProductResourceThree'
import ProductBrand from './components/ProductBrand'
import ProductSubmit from './components/ProductSubmit'
import ProductAddSuccess from './components/ProductAddSuccess'
import ProductSustain from './components/ProductSustain'
// import ProductVideo from './components/ProductVideo'
import Ubr from '/utils/common/ubr'
const ubr = new Ubr();

export default {
    name: 'Product',
    layout: 'main-head',
    components: {
        ProductSeo,
        ProductBanner,
        ProductActive,
        ProductInfo,
        ProductSku,
        ProductCard,
        ProductVip,
        ProductActivePopup,
        ProductNotice,
        ProductResourceCoupon,
        ProductPolicy,
        ProductMost,
        ProductBundle,
        ProductDesc,
        ProductResource,
        ProductReview,
        ProductAd,
        ProductEmpty,
        ProductRec,
        ProductViewed,
        ProductKeyword,
        ProductResourceThree,
        ProductBrand,
        ProductSubmit,
        ProductAddSuccess,
        ProductSustain,
        ProductRelatedCate,
        // ProductVideo
    },
    async asyncData({$api, ...context}) {
        const params = context.params;
        const seoCode = context.$ctxApi.productUrl.getUrl(params)
        const [response, shippdata, recResponse, categoryR] = await Promise.all([
            $api.product.getDetail({ // 详情
                productCode: seoCode.code,
                seoUrl: seoCode.seoUrl,
            }, false),
            $api.common.getShipping({}), // 物流
            $api.common.getRecV5Common({ // 推荐
                index: 2,
                spuCode: seoCode.code,
                pageSize: 20,
                pageNum: 1,
            }, false),
            $api.category.getMenu()
        ])
        // 商品详情
        const res = response.result || {};
        if((!res || !res?.onShelf) && context?.res?.statusCode){
            context.res?context.res.statusCode = 404:''
        }
        // 物流
        const shipres = shippdata.result?shippdata?.result[0] : {}
        // 推荐
        const recRes = recResponse.result || {};
        const recData = recRes || []
        // 分组导航
        const categorySeo = categoryR?.result || []
        return {
            hasEmpty: res.soldOut || !res.id,
            info: res,
            isServer: process.server,
            shipping: shipres,
            recData,
            categorySeo
        }
    },
    props: {},
    data() {
        return {
            startTime: 0,
            skeleton: true,
            hasEmpty: false,
            query: {},
            middleEmit: {},
            currency: {},
            info: {},           // 商品信息
            price: {},          // 商品金额
            active: {},         // 活动数据
            shipping:{},        // 物流信息
            vip: {              // vip服务
                isVipService: false
            },
            review: {},         // 评论
            skuData: {},        // sku数据
            skuSelect: {},      // sku选择数据
            activePopup: {      // 活动弹窗
                visible: false,
                type: 1,        // 1 活动 2 赠品列表
                title: '',
            },
            rec: {},            // 推荐
            recData: {},
            addSuccess: {},     // 加购成功后参数
            params: {           // 接口参数
                productCode: '',
                seoUrl: '',
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.skeleton = false
        this.setHistory()
        this.isServer && this.getDetailBuriedAjax()
        this.initialMounted()
    },
    methods: {
        // 获取商品详情接口
        getDetailAjax() {
            this.$api.product.getDetail({
                productCode: this.params.productCode,
                seoUrl: this.params.seoUrl
            }).then(response => {
                // goodsType -> 0 下架商品 1 普通商品 2 满赠商品 3 赠品 4 闪购商品 5 满折商品 6 礼盒 7 贺卡 8 满减 9 单品折扣 11 新人专享 12 礼品卡
                const res = response?.result || {};
                this.info = res
                this.skeleton = false
            })
        },
        // 获取商品详情-埋点
        getDetailBuriedAjax() {
            let clear = setTimeout(() => {
                this.$api.product.getDetail({
                    productCode: this.params.productCode,
                    seoUrl: this.params.seoUrl,
                }, false).then(response => {
                    clearTimeout(clear)
                })
            }, 1500)
        },
        // emit
        setPrice(obj) { // 金额
            this.price = obj
        },
        setActiveData(obj) { // 活动
            this.active = obj
        },
        giftMore() { // 显示赠品列表
            this.activePopup.visible = true
            this.activePopup.type = 2
        },
        changeSkuData(obj) { // sku数据
            obj.spuId = obj?.spuId||this.info?.spuId
            this.skuData = obj
        },
        changeSkuSelect(obj) { // sku选择
            obj.spuId = obj?.spuId||this.info?.spuId
            this.skuSelect = obj
        },
        skuSelectPopup(obj) { // sku弹窗内select
            const ref = this.$refs.ProductSku;
            ref && ref.handleSku(obj)
        },
        setVipData(obj) { // vip服务
            this.vip = obj
        },
        showActivePopup(obj) { // 显示活动弹窗
            this.activePopup.visible = obj.visible
            this.activePopup.type = obj.type
            this.activePopup.title = this.$translate('All Valid Benefits')
        },
        setReviewData(obj) { // 评论
            this.review = obj
        },
        showAddSuccessPopup(visible = true, obj) { // 加购成功弹窗显示
            const ref = this.$refs.ProductAddSuccess;
            ref && ref.showPopup(visible, obj)
        },
        handleAddSuccess(obj) { // 加购成功后
            this.addSuccess = obj || {}
        },
        getKeyData(key) { // 获取值
            return this[key]
        },
        emitRecObserve(obj) { // 推荐
            this.rec = obj
        },
        // seo HEAD
        setHead() {
            const currency = this.$storage.get('currency') || {};
            const pic = (this.info.pics && this.info.pics[0]) || {};
            const picUrl = this.$ctxApi.toImgUrl(pic.url)
            const head = this.$ctxApi.seoHead.initial({
                title: this.info.seoTitle,
                keywords: this.info.seoUrl,
                description: this.info.seoRemarks,
                image: picUrl
            });
            const country = this.$storage.get('country') || {}
            const scoreAverage = Math.ceil((this.info.scoreAverage || 50000) / 10000);
            const script = [{
                type: 'application/ld+json',
                json: {
                    "@context": "http://schema.org",
                    "@type": "Product",
                    "name": this.info.productName,
                    "description":this.info.seoRemarks,
                    "image": picUrl,
                    "offers": {
                        "@type": "Offer",
                        "price": (this.info.displaySalePrice || '').replaceAll(',', '.'),
                        "name": this.info.productName,
                        "priceCurrency": currency.code,
                        "image": picUrl,
                        "availability": "https://schema.org/InStock",
                        "priceValidUntil": "2042-12-20",
                        "shippingDetails": {
                            "@type": "OfferShippingDetails",
                            "shippingRate": {
                                "@type": "MonetaryAmount",
                                "value": this.shipping.displayFee,
                                "currency": currency.code
                            },
                            "shippingDestination": [
                                {
                                    "@type": "DefinedRegion",
                                    "addressCountry": country.countryCode,
                                }
                            ],
                            "deliveryTime": {
                                "@type": "ShippingDeliveryTime",
                                "handlingTime": {
                                    "@type": "QuantitativeValue",
                                    "minValue": 1,
                                    "maxValue": 5,
                                    "unitCode": "DAY"
                                },
                                "transitTime": {
                                    "@type": "QuantitativeValue",
                                    "minValue": this.shipping.minDay,
                                    "maxValue": this.shipping.maxDay,
                                    "unitCode": "DAY"
                                }
                            }
                        },
                        "hasMerchantReturnPolicy": {
                            "@type": "MerchantReturnPolicy",
                            "applicableCountry": country.countryCode,
                            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
                            "merchantReturnDays": 60,
                            "returnMethod": "https://schema.org/ReturnByMail",
                            "returnFees": "https://schema.org/FreeReturn"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": scoreAverage,
                        "bestValue": 5,
                        "ratingCount": this.info.commentTime || 1
                    }
                }
            }];
            return {
                ...head,
                script
            }
        },
        // 记录历史浏览
        setHistory() {
            let productId = this.$storage.get('product/history') || [];
            productId.unshift(this.info.id)
            productId = [...new Set(productId)]
            this.$storage.set('product/history', productId)
        },
        // ubr
        sendUbr(bool = true) {
            const nowTime = new Date().getTime();
            const skus = this.info?.skus || []
            const firstSku = skus[0] || {}
            const ge = bool ? {
                evt: 'VIEW_IN',
                evt_tag: 'ITEM_OPEN',
            } : {
                evt: 'VIEW_OUT',
                evt_tag: 'ITEM_CLOSED',
            }
            let g = {
                spm: {
                    pg: window.location.href,
                    pg_area: 91,
                    pg_area_item_id: this.info.id || 1,
                    pg_area_item_index: 1,
                },
                dat: JSON.stringify({
                    sku: this.info.id,
                    pic: firstSku.skuPic,
                    price: firstSku.displayMarketPrice,
                    spuId: this.info.spuId,
                    productCode: this.info.productCode,
                    skuName: firstSku.skuName
                }),
                t0: this.startTime,
                t: nowTime,
                ...ge
            }
            ubr.initial({
                c: 91,
                d: 1,
                g: window.btoa(encodeURI(JSON.stringify(g)))
            }, this)
        },
        // 初始化设置
        initialSet() {
            const reg = new RegExp('.htm', 'g')
            const params = this.$route.params;
            const query = this.$route.query;
            this.query = this.$ctxApi.productUrl.getUrl({...params, query});
            this.params.productCode = this.query.code || ''
            this.params.seoUrl = this.query.seoUrl.replace(reg, '') || ''
            this.middleEmit.fn = (fn, options, ...arg) => {
                return this[fn] && this[fn](options, ...arg)
            }
            this.currency = this.$storage.get('currency') || {}
            this.skuSelect = {}
        },
        initialMounted() {
            // this.$fnApi.buried.criteo({
            //     type: 'viewItem',
            //     params: this.query.code
            // })
            this.startTime = new Date().getTime()
            this.sendUbr()
            // fbq 埋点
            this.$fnApi.buried.fbq({
                type: 'ViewContent',
                params: {
                    content_type: 'product',
                    content_ids: this.info.productCode,
                    currency: 'USD',
                    value: (this.info.salePrice / 100).toFixed(2),
                    contents: [{
                        id: this.info.productCode,
                        productName: this.info.productName,
                    }]
                }
            }, this)
        }
    },
    beforeDestroy() {
        this.sendUbr(false)
    },
}
