import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4f2ad500&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2ad500",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Head: require('/home/php1/m-nuxt/components/Head.vue').default,YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
